interface LocalizedStrings {
  [key: string]: string;
}

interface LocaleJSON {
  [key: string]: LocalizedStrings;
}

export function getLocalizedStrings(locale: string, json: LocaleJSON): LocalizedStrings {
  if (json[locale]) return json[locale];

  const shortLocale = locale.split('-')[0];
  if (json[shortLocale]) return json[shortLocale];

  // find the first locale that matches the fallback locale, e.g. this would
  // choose `fr-CA` if we're given `fr-FR`, and `fr-CA` is present but `fr-FR`
  // is missing.
  const fallbackLocale = Object.keys(json).find(key => key.split('-')[0] === shortLocale);
  if (json[fallbackLocale]) return json[fallbackLocale];

  return json['en-GB'] || json['en'];
}

export function isAlphabeticKey(key: string) {
  return key.length === 1 && key.match(/[a-z]/i);
}

export function injectFont(fontSrc: string = 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap') {
  // Inject font link into the head
  // Taken from https://dev.to/akdevcraft/use-font-in-web-component-51a4

  // Using Jersey 15 font can be useful for debugging font loading as it is easier to see
  // When changing the font, make sure to update the font-family in `styles.scss`
  // const fontSrc = 'https://fonts.googleapis.com/css2?family=Jersey+15&display=swap';

  // Try to avoid adding the same link multiple times
  if (document.head.querySelector(`link[href="${fontSrc}"]`)) {
    return;
  }

  const font = document.createElement('link');
  font.href = fontSrc;
  font.rel = 'stylesheet';
  document.head.appendChild(font);
}
